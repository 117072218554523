"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/** Функция выполнится только если в течении следующих n миллисекунд не было повторного её вызозва, иначе ждёт */
function debounce(func, interval) {
    var timer = null;
    return function debounced() {
        var _this = this;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (timer)
            clearTimeout(timer);
        timer = setTimeout(function () { return func.apply(_this, args); }, interval);
    };
}
exports.default = debounce;
