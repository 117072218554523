"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
var colors_1 = require("./colors");
var shops_1 = require("./shops");
var defaultTheme = __assign(__assign(__assign({}, colors_1.defaultThemeReassing), colors_1.lightBlueCalm), colors_1.white);
var themeMap = (_a = {
        default: defaultTheme,
        // colors primary
        redBright: colors_1.redBright,
        redCalm: colors_1.redCalm,
        blueBright: colors_1.blueBright,
        blueCalm: colors_1.blueCalm,
        lightBlueBright: colors_1.lightBlueBright,
        lightBlueCalm: colors_1.lightBlueCalm,
        roseBright: colors_1.roseBright,
        roseCalm: colors_1.roseCalm,
        yellowBright: colors_1.yellowBright,
        yellowCalm: colors_1.yellowCalm,
        greenBright: colors_1.greenBright,
        greenCalm: colors_1.greenCalm,
        turquoiseBright: colors_1.turquoiseBright,
        turquoiseCalm: colors_1.turquoiseCalm,
        darkBlueBright: colors_1.darkBlueBright,
        darkBlueCalm: colors_1.darkBlueCalm,
        orangeBright: colors_1.orangeBright,
        orangeCalm: colors_1.orangeCalm,
        black: colors_1.black,
        // colors secondary
        white: colors_1.white,
        // магазины
        holodilnik: shops_1.holodilnikTheme,
        gorenje: shops_1.gorenjeTheme,
        bosch: shops_1.boschTheme,
        maunfeld: shops_1.maunfeldTheme,
        teka: shops_1.tekaTheme,
        kaiser: shops_1.kaiserTheme
    },
    _a['asko-russia'] = shops_1.askoTheme,
    _a['korting-dealer'] = shops_1.kortingTheme,
    _a['hitachi-holodilnik'] = shops_1.hitachiTheme,
    // поддержка старого: цвета
    _a.pink = colors_1.roseCalm,
    _a.blue = colors_1.blueCalm,
    _a.sky = colors_1.lightBlueBright,
    _a.red = colors_1.redCalm,
    // поддержка старого: магазины
    _a.thermexTheme = colors_1.redCalm,
    _a.panasonicTheme = colors_1.blueCalm,
    _a);
function getAndApplyTheme(themeName, oldTheme) {
    if (oldTheme === void 0) { oldTheme = defaultTheme; }
    var theme = __assign({}, oldTheme);
    if (!themeName) {
        applyTheme(theme);
        return theme;
    }
    var names = themeName === null || themeName === void 0 ? void 0 : themeName.split('.');
    names.forEach(function (name) {
        var themePart = themeMap[name];
        if (themePart)
            theme = __assign(__assign({}, theme), themePart);
    });
    applyTheme(theme);
    return theme;
}
exports.default = getAndApplyTheme;
function applyTheme(theme) {
    var keys = Object.keys(theme);
    keys.forEach(function (key) {
        var value = theme[key];
        document.body.style.setProperty(key, value || null);
    });
}
