"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vars_1 = require("../configs/vars");
/** @todo удалить, эти данные перенести в стейт */
var Settings = /** @class */ (function () {
    function Settings() {
        this.defaultCityPhone = vars_1.DEFAULT_PHONE_NUMBER;
        this.serviceUrl = 'https://api.clicker.one'; // Хардкод, с верселя не взять переменную среды, если починится, то раскомментировать ниже
        // if (!process.env.NEXT_PUBLIC_MAIN_API_URL) throw new Error('Отсутствует MAIN_API_URL'); // messages.mainApiUrlMissing
        // this._serviceUrl = process.env.NEXT_PUBLIC_MAIN_API_URL;
    }
    return Settings;
}());
exports.default = Settings;
