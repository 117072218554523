"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Функция запускается не чаще чем раз в N миллисекунд
 * @see https://learn.javascript.ru/call-apply-decorators
 */
function throttle(func, ms) {
    var isThrottled = false;
    var lastReturnValue;
    var savedArgs;
    var savedThis;
    function wrapper() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (isThrottled) {
            savedArgs = args;
            savedThis = this;
            return lastReturnValue;
        }
        lastReturnValue = func.apply(this, args); // (1)
        isThrottled = true;
        setTimeout(function () {
            isThrottled = false; // (3)
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
        return lastReturnValue;
    }
    return wrapper;
}
exports.default = throttle;
