"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reviewResponseDTOToModelMapper = void 0;
function reviewResponseDTOToModelMapper(r) {
    return __assign(__assign({}, r), { id: r.entityId, text: r.review, clientName: r.author, cityName: r.city, date: r.entityCreatedDate });
}
exports.reviewResponseDTOToModelMapper = reviewResponseDTOToModelMapper;
