"use strict";
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
Object.defineProperty(exports, "__esModule", { value: true });
exports.hitachiTheme = exports.kortingTheme = exports.askoTheme = exports.kaiserTheme = exports.tekaTheme = exports.maunfeldTheme = exports.boschTheme = exports.gorenjeTheme = exports.holodilnikTheme = void 0;
exports.holodilnikTheme = (_a = {},
    _a['--is-font-family'] = "'PT Sans',sans-serif",
    _a['--is-theme-color-primary'] = '#0595E6',
    _a['--is-theme-color-primary-hover'] = '#06A4FD',
    _a['--is-font-color-primary'] = '#464B51',
    _a['--is-font-color-secondary'] = '#7D858C',
    _a['--is-font-color-small'] = '#ADADAD',
    _a['--is-svg-color-primary'] = '#2A62A0',
    _a['--is-modal-price-color'] = '#EB5757',
    _a['--is-modal-discount-color'] = '#EB5757',
    _a['--is-modal-button-weight'] = 'bold',
    _a['--is-overlay-background-color'] = 'rgba(0, 0, 0, 0)',
    _a);
exports.gorenjeTheme = (_b = {},
    _b['--is-button-background-color-primary'] = '#00B9AD',
    _b['--is-button-background-color-primary-hover'] = '#00D5CA',
    _b['--is-theme-color-primary'] = '#00B9AD',
    _b['--is-theme-color-primary-hover'] = '#00D5CA',
    _b['--is-svg-color-primary'] = '#00B9AD',
    _b['--is-modal-discount-color'] = '#D7102A',
    _b['--is-font-color-primary'] = '#000000',
    _b['--is-font-color-secondary'] = '#000000',
    _b['--is-font-color-small'] = '#7B7C7F',
    _b['--is-modal-price-color'] = '#000000',
    _b['--is-overlay-background-color'] = 'rgba(0, 0, 0, 0.7)',
    _b['--is-font-family'] = "'Helvetica Neue Cyr', sans-serif",
    _b);
exports.boschTheme = (_c = {},
    _c['--is-button-background-color-primary'] = '#00639A',
    _c['--is-button-background-color-primary-hover'] = '#0078B7',
    _c['--is-theme-color-primary'] = '#00639A',
    _c['--is-theme-color-primary-hover'] = '#0078B7',
    _c['--is-svg-color-primary'] = '#00639A',
    _c['--is-modal-discount-color'] = '#D7102A',
    _c['--is-font-color-primary'] = '#000000',
    _c['--is-font-color-secondary'] = '#000000',
    _c['--is-font-color-small'] = '#7B7C7F',
    _c['--is-modal-price-color'] = '#000000',
    _c['--is-overlay-background-color'] = 'rgba(0, 0, 0, 0.7)',
    _c['--is-font-family'] = "'Helvetica Neue Cyr', sans-serif",
    _c);
exports.maunfeldTheme = (_d = {},
    _d['--is-button-background-color-primary'] = '#2D38A9',
    _d['--is-button-background-color-primary-hover'] = '#3644C6',
    _d['--is-theme-color-primary'] = '#2D38A9',
    _d['--is-theme-color-primary-hover'] = '#3644C6',
    _d['--is-svg-color-primary'] = '#2D38A9',
    _d['--is-modal-discount-color'] = '#D7102A',
    _d['--is-font-color-primary'] = '#000000',
    _d['--is-font-color-secondary'] = '#000000',
    _d['--is-font-color-small'] = '#7B7C7F',
    _d['--is-modal-price-color'] = '#000000',
    _d['--is-overlay-background-color'] = 'rgba(0, 0, 0, 0.7)',
    _d['--is-font-family'] = "'Helvetica Neue Cyr', sans-serif",
    _d);
exports.tekaTheme = (_e = {},
    _e['--is-button-background-color-primary'] = '#C8102E',
    _e['--is-button-background-color-primary-hover'] = '#E11338',
    _e['--is-theme-color-primary'] = '#C8102E',
    _e['--is-theme-color-primary-hover'] = '#E11338',
    _e['--is-svg-color-primary'] = '#C8102E',
    _e['--is-modal-discount-color'] = '#D7102A',
    _e['--is-font-color-primary'] = '#000000',
    _e['--is-font-color-secondary'] = '#000000',
    _e['--is-font-color-small'] = '#7B7C7F',
    _e['--is-modal-price-color'] = '#000000',
    _e['--is-overlay-background-color'] = 'rgba(0, 0, 0, 0.7)',
    _e['--is-font-family'] = "'Helvetica Neue Cyr', sans-serif",
    _e);
exports.kaiserTheme = (_f = {},
    _f['--is-button-background-color-primary'] = '#DD0017',
    _f['--is-button-background-color-primary-hover'] = '#F0001C',
    _f['--is-theme-color-primary'] = '#DD0017',
    _f['--is-theme-color-primary-hover'] = '#F0001C',
    _f['--is-svg-color-primary'] = '#DD0017',
    _f['--is-modal-discount-color'] = '#D7102A',
    _f['--is-font-color-primary'] = '#000000',
    _f['--is-font-color-secondary'] = '#000000',
    _f['--is-font-color-small'] = '#7B7C7F',
    _f['--is-modal-price-color'] = '#000000',
    _f['--is-overlay-background-color'] = 'rgba(0, 0, 0, 0.7)',
    _f['--is-font-family'] = "'Helvetica Neue Cyr', sans-serif",
    _f);
exports.askoTheme = (_g = {},
    _g['--is-button-background-color-primary'] = '#D7102A',
    _g['--is-button-background-color-primary-hover'] = '#D61616',
    _g['--is-theme-color-primary'] = '#D7102A',
    _g['--is-theme-color-primary-hover'] = '#D61616',
    _g['--is-svg-color-primary'] = '#D7102A',
    _g['--is-modal-discount-color'] = '#D7102A',
    _g['--is-font-color-primary'] = '#000000',
    _g['--is-font-color-secondary'] = '#000000',
    _g['--is-font-color-small'] = '#7B7C7F',
    _g['--is-modal-price-color'] = '#000000',
    _g['--is-overlay-background-color'] = 'rgba(0, 0, 0, 0.7)',
    _g['--is-font-family'] = "'Helvetica Neue Cyr', sans-serif",
    _g);
exports.kortingTheme = (_h = {},
    _h['--is-button-background-color-primary'] = '#14BEF0',
    _h['--is-button-background-color-primary-hover'] = '#18D9FA',
    _h['--is-theme-color-primary'] = '#14BEF0',
    _h['--is-theme-color-primary-hover'] = '#18D9FA',
    _h['--is-svg-color-primary'] = '#14BEF0',
    _h['--is-modal-discount-color'] = '#14BEF0',
    _h['--is-font-color-primary'] = '#000000',
    _h['--is-font-color-secondary'] = '#000000',
    _h['--is-font-color-small'] = '#7B7C7F',
    _h['--is-modal-price-color'] = '#000000',
    _h['--is-overlay-background-color'] = 'rgba(0, 0, 0, 0.7)',
    _h['--is-font-family'] = "'Helvetica Neue Cyr', sans-serif",
    _h);
exports.hitachiTheme = (_j = {},
    _j['--is-button-background-color-primary'] = '#B1000E',
    _j['--is-button-background-color-primary-hover'] = '#CD0011',
    _j['--is-theme-color-primary'] = '#B1000E',
    _j['--is-theme-color-primary-hover'] = '#CD0011',
    _j['--is-svg-color-primary'] = '#B1000E',
    _j['--is-modal-discount-color'] = '#B1000E',
    _j['--is-font-color-primary'] = '#000000',
    _j['--is-font-color-secondary'] = '#000000',
    _j['--is-font-color-small'] = '#7B7C7F',
    _j['--is-modal-price-color'] = '#000000',
    _j['--is-overlay-background-color'] = 'rgba(0, 0, 0, 0.7)',
    _j['--is-font-family'] = "'Helvetica Neue Cyr', sans-serif",
    _j);
