"use strict";
// Дефолтный конструктор
Object.defineProperty(exports, "__esModule", { value: true });
var Block = /** @class */ (function () {
    function Block() {
        this.replaceTag = function ($old, newTag) {
            var _a;
            var $new = document.createElement(newTag);
            if ($old.attributes.length) {
                for (var index = $old.attributes.length - 1; index >= 0; --index) {
                    var attr = $old.attributes.item(index);
                    $new.setAttribute(attr.nodeName, attr.nodeValue);
                }
            }
            $new.innerHTML = $old.innerHTML;
            (_a = $old.parentNode) === null || _a === void 0 ? void 0 : _a.replaceChild($new, $old);
            return $new;
        };
    }
    return Block;
}());
exports.default = Block;
