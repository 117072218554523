"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countPrices = exports.formatPrice = void 0;
// Не переписывал, взял из legacy
function formatPrice(priceNumber, decPlaces, decSep, thouSep) {
    var number = priceNumber;
    var sign = number < 0 ? '-' : '';
    var i = String(parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces))));
    var j = (j = i.length) > 3 ? j % 3 : 0;
    return (sign +
        (j ? i.substr(0, j) + thouSep : '') +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1".concat(thouSep)) +
        (decPlaces
            ? decSep +
                Math.abs(number - i)
                    .toFixed(decPlaces)
                    .slice(2)
            : ''));
}
exports.formatPrice = formatPrice;
// * cart обычно приходит местный со страницы. Если логика сменится и cart будет глобальный, то тут нужны будут доп проверки на существование
function countPrices(services) {
    var totalPriceWithoutDiscount = 0;
    var totalPriceWithDiscount = 0;
    var hasDiscount = false;
    var isFree = false;
    var totalQuantity = 0;
    services.forEach(function (service) {
        totalQuantity += service.quantity;
        var normalPrice = service.price * service.quantity;
        totalPriceWithoutDiscount += normalPrice;
        if (service.discount) {
            hasDiscount = true;
            totalPriceWithDiscount += service.discountPrice * service.quantity;
        }
        else {
            totalPriceWithDiscount += normalPrice;
        }
    });
    isFree = hasDiscount && totalPriceWithDiscount === 0;
    return { totalPriceWithoutDiscount: totalPriceWithoutDiscount, totalPriceWithDiscount: totalPriceWithDiscount, hasDiscount: hasDiscount, isFree: isFree, totalQuantity: totalQuantity };
}
exports.countPrices = countPrices;
