"use strict";
// Primary
var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultThemeReassing = exports.white = exports.black = exports.orangeCalm = exports.orangeBright = exports.darkBlueCalm = exports.darkBlueBright = exports.turquoiseCalm = exports.turquoiseBright = exports.greenCalm = exports.greenBright = exports.yellowCalm = exports.yellowBright = exports.roseCalm = exports.roseBright = exports.lightBlueCalm = exports.lightBlueBright = exports.blueCalm = exports.blueBright = exports.redCalm = exports.redBright = void 0;
exports.redBright = (_a = {},
    _a['--is-theme-color-primary'] = '#EC1B25',
    _a['--is-theme-color-primary-hover'] = '#FF3640',
    _a);
exports.redCalm = (_b = {},
    _b['--is-theme-color-primary'] = '#E03F3F',
    _b['--is-theme-color-primary-hover'] = '#FA4242',
    _b);
exports.blueBright = (_c = {},
    _c['--is-theme-color-primary'] = '#5DA1F0',
    _c['--is-theme-color-primary-hover'] = '#66A9F8',
    _c);
exports.blueCalm = (_d = {},
    _d['--is-theme-color-primary'] = '#1C72D7',
    _d['--is-theme-color-primary-hover'] = '#227BE4',
    _d);
exports.lightBlueBright = (_e = {},
    _e['--is-theme-color-primary'] = '#5DBDF0',
    _e['--is-theme-color-primary-hover'] = '#6ECDFF',
    _e);
exports.lightBlueCalm = (_f = {},
    _f['--is-theme-color-primary'] = '#0595e6',
    _f['--is-theme-color-primary-hover'] = '#0da2f5',
    _f);
exports.roseBright = (_g = {},
    _g['--is-theme-color-primary'] = '#F04A72',
    _g['--is-theme-color-primary-hover'] = '#FD577F',
    _g);
exports.roseCalm = (_h = {},
    _h['--is-theme-color-primary'] = '#F59A9B',
    _h['--is-theme-color-primary-hover'] = '#F2ACAD',
    _h);
exports.yellowBright = (_j = {},
    _j['--is-theme-color-primary'] = '#EDD34A',
    _j['--is-theme-color-primary-hover'] = '#F5DB53',
    _j);
exports.yellowCalm = (_k = {},
    _k['--is-theme-color-primary'] = '#E1CB08',
    _k['--is-theme-color-primary-hover'] = '#EBD512',
    _k);
exports.greenBright = (_l = {},
    _l['--is-theme-color-primary'] = '#22D12B',
    _l['--is-theme-color-primary-hover'] = '#30E138',
    _l);
exports.greenCalm = (_m = {},
    _m['--is-theme-color-primary'] = '#3CB442',
    _m['--is-theme-color-primary-hover'] = '#44C74A',
    _m);
exports.turquoiseBright = (_o = {},
    _o['--is-theme-color-primary'] = '#4EDBD3',
    _o['--is-theme-color-primary-hover'] = '#67EBE3',
    _o);
exports.turquoiseCalm = (_p = {},
    _p['--is-theme-color-primary'] = '#1DD6CB',
    _p['--is-theme-color-primary-hover'] = '#23E3D8',
    _p);
exports.darkBlueBright = (_q = {},
    _q['--is-theme-color-primary'] = '#1434D9',
    _q['--is-theme-color-primary-hover'] = '#2948EB',
    _q);
exports.darkBlueCalm = (_r = {},
    _r['--is-theme-color-primary'] = '#2438A1',
    _r['--is-theme-color-primary-hover'] = '#3F53BF',
    _r);
exports.orangeBright = (_s = {},
    _s['--is-theme-color-primary'] = '#EF6603',
    _s['--is-theme-color-primary-hover'] = '#F28535',
    _s);
exports.orangeCalm = (_t = {},
    _t['--is-theme-color-primary'] = '#FF9141',
    _t['--is-theme-color-primary-hover'] = '#FFA869',
    _t);
exports.black = (_u = {},
    _u['--is-theme-color-primary'] = '#121212',
    _u['--is-theme-color-primary-hover'] = '#404040',
    _u);
// Secondary
exports.white = (_v = {},
    _v['--is-font-family'] = 'Helvetica, sans-serif',
    _v['--is-font-color-primary'] = '#0f1525',
    _v['--is-font-color-secondary'] = '#484f68',
    _v['--is-font-color-small'] = '#acacac',
    _v['--is-modal-discount-color'] = '#31BA3F',
    _v['--is-overlay-background-color'] = 'rgba(0, 0, 0, 0.6)',
    _v['--is-modal-button-weight'] = 'normal',
    _v['--is-modal-background-color'] = '#FFFFFF',
    _v['--is-modal-footer-divider-color'] = '#F0F0F0',
    _v['--is-modal-review-item-border-color'] = '#F0F0F0',
    _v);
// Default reassign
exports.defaultThemeReassing = (_w = {},
    _w['--is-link-color-primary'] = 'var(--is-theme-color-primary)',
    _w['--is-link-color-primary-hover'] = 'var(--is-theme-color-primary-hover)',
    _w['--is-button-background-color-primary'] = 'var(--is-theme-color-primary)',
    _w['--is-button-background-color-primary-hover'] = 'var(--is-theme-color-primary-hover)',
    _w['--is-svg-color-primary'] = 'var(--is-theme-color-primary)',
    _w['--is-modal-price-color'] = 'var(--is-font-color-primary)',
    _w['--is-modal-review-item-border-color'] = 'var(--is-modal-footer-divider-color)',
    _w);
